import React from "react";
import { Container, Row, Col, Button, Card, Badge } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import parse from "html-react-parser";
import "./../styles/components/hero-section-home.scss";
import ReactPlayer from "react-player";

const HeroSectionHome = ({
	title,
	subtitle,
	buttonText,
	buttonLink,
	insuranceTypes,
	insuranceTypesTitle,
	imageOneData,
	imageTwoData,
	imageThreeData,
	altTexts,
	video,
}) => {
	// Retrieve images using the 'getImage' helper function
	const imageOne = getImage(imageOneData);
	const imageTwo = getImage(imageTwoData);
	const imageThree = getImage(imageThreeData);

	return (
		<Container fluid className="px-0 h-100 hero-section-home">
			<Row className="justify-content-center   h-100">
				<Col
					lg={{ order: "first" }}
					xs={{ order: "last" }}
					className="p-5 pt-xxl-7"
				>
					{/* Main heading and subtext */}
					{parse(title)}
					<p className="my-4">{subtitle}</p>
					{/* Call to action button */}
					<Button
						size="lg"
						className="mb-4 gradient-button w-100 w-md-auto px-4 py-3 text-uppercase get-quotes-btn"
						as={Link}
						to={buttonLink}
					>
						{buttonText}
					</Button>
					{/* Insurance types represented as styled divs */}
					<div className="d-md-flex   align-items-center">
						<h6 className="mb-0 mt-2 me-3 text-heading-color">
							{insuranceTypesTitle}
						</h6>
						<div style={{ flexWrap: "wrap" }}>
							{insuranceTypes.map((type, idx) => (
								<Button
									as={Link}
									to={`insurance/${type?.insurance?.slug}`}
									variant="outline-secondary"
									style={{ borderRadius: "200px" }}
									className="p-3 py-1 me-3 mt-2 fs-6 secondary-link"
								>
									{type?.insurance?.title}
								</Button>
							))}
						</div>
					</div>
				</Col>
				<Col className="" style={{ minHeight: "100%" }} lg={6}>
					{/* <GatsbyImage
						className="w-100 h-100"
						image={imageTwo}
						alt={altTexts[1]}
					/> */}
					<div className="bg-black h-100">
						<ReactPlayer
							width="100%"
							height="100%"
							playing
							muted
							controls
							playsinline
							url={video}
						/>
					</div>
				</Col>
				{/* <Col
					style={{
						background:
							"linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
						minHeight: "100%",
					}}
					className="p-md-6 pt-8 px-lg-0 px-xl-6 pb-6 position-relative"
					lg={6}
					xs={12}
				>
					
					<Row className="h-100 my-lg-6">
						<Col xs={3} className=" position-relative">
							<div
								style={{
									overflow: "hidden",
									zIndex: 1,
									borderRadius: "15px",
									boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
								}}
								className="position-absolute mw-100 bottom-10 end--50"
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imageOne}
									alt={altTexts[0]}
								/>
							</div>
						</Col>
						<Col style={{ zIndex: 0 }} xs={6} className="position-relative">
							<div
								style={{
									overflow: "hidden",
									borderRadius: "15px",
									boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
								}}
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imageTwo}
									alt={altTexts[1]}
								/>
							</div>
						</Col>
						<Col xs={3} className="position-relative">
							<div
								style={{
									zIndex: 1,
									borderRadius: "15px",
									boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
									overflow: "hidden",
								}}
								className="position-absolute mw-100 start--50 top--20"
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imageThree}
									alt={altTexts[2]}
								/>
							</div>
						</Col>
					</Row>
				</Col> */}
			</Row>
		</Container>
	);
};

export default HeroSectionHome;
